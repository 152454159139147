import React from "react";
import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

class Tr extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Teamfluencer - Nano Influencer Pazarlama Platformu & Ajansı</title>
          <meta
            name="description"
            content="Nano ve Mikro Influencer'lar aracılığıyla markanız Instagram'da doğal bir şekilde viral olsun! Teamfluencer Nano Influencer'lar ile markaları bir araya getiren dijital Influencer marketing platformudur."
          />
          <link rel="canonical" href="http://teamfluencer.co" />
          <meta
            name="keywords"
            content="dijital pazarlama,influencer,teamflencer,team,fluencer,marketing,digital marketing,influencer marketing,nano influencer,nano-influencer,nano influencer marketing,nano-influencer marketing,viral marketing,social media marketing,social media,instagram"
          />
        </Helmet>
        <Redirect to="/" />
      </React.Fragment>
    );
  }
}

export default Tr;
