import React from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { IconContext } from "react-icons";
import "./payment.css";
import { useTranslation } from "react-i18next";
import Toolbar from "../../toolbar/Toolbar";
import FooterComponent from "../../footer/footerComponent";

const PaymentApproved = (props) => {
  var query = props.location.search;
  query = query.substring(1)
  var queries = query.split("&")
  // console.log("query : ", queries)
  var newArray = [];
  for (var i = 0; i < queries.length; i++) {
    newArray[queries[i].split("=")[0]] = queries[i].split("=")[1]
  }
  // fetch(`https://api.teamfluencer.co/brand/stripeSuccess`,
  //   {
  //     method: "post",
  //     headers: new Headers({ "content-type": "application/json" }),
  //     credentials: "include",
  //     body: JSON.stringify({ payment_intent: newArray["payment_intent"] }),
  //   }
  // )
  //   .then((updateRes) => updateRes.json())
  //   .then((data) => {
  //     console.log("data", data)
  //   })
  //   .catch((err) => {
  //     console.log(err)
  //   });

  const { t } = useTranslation();
  const gotoLogedIn = () => {
    props.history.push("/logedIn");
  };
  return (
    <React.Fragment>
      <Toolbar></Toolbar>
      <div className="paymentPage">
        <IconContext.Provider
          className="icon"
          value={{ color: "#06c472", size: 60 }}
        >
          <AiOutlineCheck />
        </IconContext.Provider>
        <div className="paymentText">{t("paymentRecieved")}!</div>
        <button className="retryButton" onClick={() => gotoLogedIn()}>
          {t("BrowseYourAccount")}
        </button>
      </div>
      <FooterComponent></FooterComponent>
    </React.Fragment>
  );
};

export default PaymentApproved;
