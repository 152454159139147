import React from "react";
import { Component } from "react";
import { Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";

class En extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Teamfluencer - Nano Influencer Marketing Platform</title>
          <meta
            name="description"
            content="Get your brand naturally viral on Instagram via Nano Influencers! Teamfluencer is a digital Influencer marketing platform that brings together Nano Influencers and brands."
          />
          <link rel="canonical" href="http://teamfluencer.co" />
          <meta
            name="keywords"
            content="influencer,teamflencer,team,fluencer,marketing,digital marketing,influencer marketing,nano influencer,nano-influencer,nano influencer marketing,nano-influencer marketing,viral marketing,social media marketing,social media,instagram"
          />
        </Helmet>
        <Redirect to="/" />
      </React.Fragment>
    );
  }
}

export default En;
