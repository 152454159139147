import React, { Component } from 'react';
import '../login/Login.css';
import '../components/signup/signup.css';
import '../components/signup/steps.css';
import { Link, withRouter } from 'react-router-dom';
import Toolbar from '../toolbar/Toolbar';
import Spinner from '../containers/spinner/spinner';
import { withTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { BiCheck } from 'react-icons/bi';

class ForgotMyPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      isEmailSent: false,
      errors: [],
    };
  }

  componentDidMount() {}

  async handleChangeEmail(e) {
    this.setState({ email: e.target.value });
  }

  validateEmail() {
    let errors = {};
    const { t } = this.props;
    if (!this.state.email) {
      errors['email'] = t('PleaseEnterAnEmail');
    }

    if (typeof this.state.email !== 'undefined') {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(this.state.email)) {
        errors['emailInvalid'] = t('PleaseEnterCorrectEmail');
      }
    }
    if (Object.keys(errors).length === 0) {
      this.setState({ errors: '' });
      this.sendEmail();
    } else {
      this.setState({ errors: errors });
    }
  }
  async sendEmail() {
    await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/brand/auth/passwordEmail`,
      {
        credentials: 'include',
        method: 'POST',
        headers: new Headers({ 'content-type': 'application/json' }),
        body: JSON.stringify({
          email: this.state.email,
        }),
      }
    )
      .then((res) => res.json())
      .then((resFin) => {
        this.setState({ isEmailSent: true });
        //window.amplitude.logEvent("user_password_reset_email_sent");
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleKeyPress(e) {
    if (e.keyCode === 13) {
      this.validateLogin();
    }
  }

  render() {
    const { t } = this.props;
    return (
      <div className="body">
        {this.state.checkingAuth ? (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Spinner></Spinner>
          </div>
        ) : (
          <div className="flex-1 flex-col">
            <Toolbar active={this.props.location.pathname}></Toolbar>
            <div className="w-10/12  flex-col justify-center items-center mx-auto py-20">
              {this.state.isEmailSent ? (
                <div className="w-3/12 mx-auto">
                  <div className="center2 passwordResetBoxes">
                    <p className="text-sm">{t('EmailSent')}</p>
                    <div className="emailSentIcon">
                      <IconContext.Provider
                        value={{ color: '#06c472', size: 50 }}>
                        <BiCheck />
                      </IconContext.Provider>
                    </div>
                    <Link
                      className="loginButtons disabledLoginButton"
                      to="/login">
                      <button className="loginButtons disabledLoginButton">
                        {t('SignIn')}
                      </button>
                    </Link>
                  </div>
                </div>
              ) : (
                <div className="w-3/12 mx-auto">
                  <span className="span text-center">
                    {t('GetYourNewPassword')}
                  </span>
                  <div className="input-container">
                    <label htmlFor="userEmail">{t('Email')}</label>
                    <input
                      name="email"
                      id="userEmail"
                      placeholder="Email"
                      onChange={(e) => this.handleChangeEmail(e)}
                      onKeyDown={(e) => this.handleKeyPress(e)}
                      required
                    />
                    {!this.state.errors['email'] ? null : (
                      <p className="danger-text">
                        {this.state.errors['email']}
                      </p>
                    )}
                    {!this.state.errors['emailInvalid'] ? null : (
                      <p className="danger-text">
                        {this.state.errors['emailInvalid']}
                      </p>
                    )}
                    <button
                      type="submit"
                      className="loginButtons "
                      onClick={() => this.validateEmail()}>
                      {t('GetYourNewPassword')}
                    </button>
                  </div>
                  <h2 className="footer-text w-auto text-sm mt-14 ">
                    {t('AlreadyAMember')}
                  </h2>
                  <Link
                    className="w-fit flex flex-row justify-between items-center hover:opacity-70 bg-slate-100 px-4 py-2 rounded-md mt-2 "
                    to="/login">
                    <p className="signup-url text-sm mx-4">
                      <strong> {t('LogIn')}</strong>
                    </p>
                  </Link>
                  <h2 className="footer-text w-auto text-sm mt-5">
                    {t('Are you a content creator/influencer?')}
                  </h2>
                  <button
                    className="flex flex-row justify-between items-center hover:opacity-70 bg-slate-100 px-4 py-2 rounded-md mt-2"
                    onClick={() => {
                      window.open('https://onelink.to/teamfluencer', '_blank');
                    }}>
                    <p className="signup-url text-sm mx-4">
                      <strong> {t('Register as an influencer')}</strong>
                    </p>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(withRouter(ForgotMyPassword));
