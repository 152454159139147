import ClipLoader from "react-spinners/ClipLoader";
import React from "react";

const Spinner = (props) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ClipLoader size={50} color={"#ff006a"} loading={props.loading} />
    </div>
  );
};

export default Spinner;
