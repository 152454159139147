const Auth = {
  isAuthenticated: false,
  isAdmin: false,
  brand_name: "",
  brand_logo: "",
  balance: 0,
  setBrand_Name(brand_name) {
    this.brand_name = brand_name;
  },
  getBrand_Name() {
    return this.brand_name;
  },
  setBalance(balance) {
    this.balance = balance;
  },
  getBalance() {
    return this.balance;
  },
  setBrand_Logo(brand_logo) {
    this.brand_logo = brand_logo;
  },
  getBrand_Logo() {
    return this.brand_logo;
  },
  authenticate() {
    this.isAuthenticated = true;
  },
  authenticateAdmin() {
    this.isAdmin = true;
  },
  signout() {
    this.isAuthenticated = false;
  },
  getAuth() {
    return this.isAuthenticated;
  },
  getAuthAdmin() {
    return this.isAdmin;
  },
};
export default Auth;
