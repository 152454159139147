import React, { Component } from 'react';
import './Toolbar.css';
import { Link } from 'react-router-dom';
import { BiMenu } from 'react-icons/bi';
import { IconContext } from 'react-icons';
import { withTranslation } from 'react-i18next';
import { PopupText } from 'react-calendly';
import LoginIcon from '@mui/icons-material/Login';
class Toolbar extends Component {
  constructor() {
    super();
    this.dropdownMenu = React.createRef();
    this.state = {
      isLoginActive: true,
      isSignupActive: false,
      showMenu: false,
    };
    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener('click', this.closeMenu);
    });
  }

  closeMenu(event) {
    this.setState({
      showMenu: false,
    });

    document.removeEventListener('click', this.closeMenu);
  }
  changeActives() {
    this.setState({
      isLoginActive: !this.state.isLoginActive,
      isSignupActive: !this.state.isSignupActive,
    });
  }

  componentDidMount() {
    if (this.props.active === '/signup') {
      this.changeActives();
    } else if (this.props.active === '/login') {
    } else {
      this.setState({
        isLoginActive: false,
        isSignupActive: false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const request_demo_button = (
      <div className="hover mr-5">
        <PopupText
          branding
          styles={{
            fontSize: '15px',
            // backgroundColor: "rgb(255, 250, 234)",
            // color: "rgb(231, 145, 3)",
            color: '#ff006a',
            height: '100%',
            paddingLeft: '5px',
            fontWeight: '500',
            minWidth: '160px',
            textAlign: 'center',
            borderRadius: '4px',
            // fontWeight: 600
            fontFamily: 'Inter-Medium',
            backgroundColor: '#F1F5F9',
          }}
          color="#ff006a"
          pageSettings={{
            backgroundColor: '#ff006a',
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: '#ff006a',
            textColor: 'ff006a',
          }}
          text={t('LetsMeetForYourBrand')}
          textColor="#ff006a"
          className="demoTag"
          url="https://calendly.com/teamfluencer"
        />
      </div>
    );
    return (
      <div className="Toolbar sticky font-init">
        <a href="https://teamfluencer.co">
          <div className="teamf"></div>
        </a>
        <IconContext.Provider
          value={{ color: '#FB255C', size: 25, className: 'menuIcon' }}>
          <BiMenu onClick={this.showMenu} />
        </IconContext.Provider>
        <div className="buttons">
          <React.Fragment>
            {request_demo_button}
            {this.state.isLoginActive ? (
              <Link className="invis" to="/signup">
                <button className="active" onClick={() => this.changeActives()}>
                  {t('SignUpAsBrand')}
                </button>
              </Link>
            ) : (
              <Link className="invis" to="/login">
                <button className="active" onClick={() => this.changeActives()}>
                  {t('LogInAsBrand')}
                </button>
              </Link>
            )}
          </React.Fragment>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Toolbar);
