import React from "react";
import { Component } from "react";
import Spinner from "../containers/spinner/spinner";

class GooglePlay extends Component {
  constructor() {
    super();
    this.redirect();
  }
  componentDidMount() {
    window.location.replace(
      "https://play.google.com/store/apps/details?id=com.teamfluencer.teamfluencer"
    );
  }
  redirect() {
    window.amplitude.logEvent("user_redirected_to_GooglePlay");
  }
  render() {
    return <Spinner></Spinner>;
  }
}

export default GooglePlay;
