import React, { Component, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import '../../components/signup/signup.css';
import Spinner from '../spinner/spinner';
import PaymentApproved from '../paymentRedirects/paymentApproved';
import PaymentDeclined from '../paymentRedirects/paymentDeclined';
import LandingPage from '../../landingpage/landingpage';
import Auth from '../../../helpers/Auth';
import ForgotMyPassword from '../../forgot-my-password/ForgotMyPassword';
import Admin from '../../admin/admin';
import Unsubscribe from '../../unsunscribe/unsubscribe';
import Forms from '../../forms/forms';
import RedirectToApp from '../../redirectToApp/redirectToApp';
import GooglePlay from '../../redirectToApp/gooleplay';
import AppStore from '../../redirectToApp/appstore';
import Tr from '../../languages/tr';
import En from '../../languages/en';
import Bio from '../../forms/bio';
import NoMatch from '../noMatch/noMatch';

const Signup = React.lazy(() => import('../../components/signup/signup'));
const Login = React.lazy(() => import('../../login/Login'));
const Edvido = React.lazy(() => import('../../redirectEdvido/edvido'));
const LogedIn = React.lazy(() => import('../logedIn/logedIn'));
const Forward = React.lazy(() => import('../logedIn/forward'));
const TermsAndConditions = React.lazy(() =>
  import('../../policies/termsAndConditions')
);
const Pricing = React.lazy(() => import('../../pricing/pricing.js'));
const PrivacyPolicy = React.lazy(() => import('../../policies/privacyPolicy'));
const AdminLogin = React.lazy(() => import('../../admin/adminLogin'));
const ResetPassword = React.lazy(() =>
  import('../../forgot-my-password/ResetPassword')
);

class homepage extends Component {
  render() {
    return (
      <div className="fullHeight">
        <Suspense fallback={<Spinner></Spinner>}>
          <Switch>
            <Route path="/homepage" component={LandingPage}></Route>
            <Route path="/login" component={Login}></Route>
            <Route path="/signup" component={Signup}></Route>
            <Route path="/partner" component={Edvido}></Route>
            <Route
              path="/forgotmypassword"
              component={ForgotMyPassword}></Route>
            <Route path="/password-reset/" component={ResetPassword}></Route>
            <Route
              path="/logedIn"
              render={(props) =>
                Auth.isAuthenticated === true ? (
                  <LogedIn></LogedIn>
                ) : (
                  <Redirect to="/login" />
                )
              }></Route>
            <Route
              path="/new-campaign"
              render={(props) =>
                <Forward path="/new-campaign"></Forward>

              }></Route>
            <Route
              path="/campaigns"
              render={(props) =>
                <Forward path="/campaigns"></Forward>
              }></Route>
            <Route
              path="/campaign-details"
              render={(props) => (
                <Forward path="/campaign-details"></Forward>
              )}></Route>
            <Route
              path="/balance"
              render={(props) => <Forward path="/balance"></Forward>}></Route>
            <Route
              path="/billing-information"
              render={(props) => <Forward path="/billing-information"></Forward>}></Route>
            <Route
              path="/payment"
              render={(props) => <Forward path="/payment"></Forward>}></Route>
            <Route
              path="/account"
              render={(props) => <Forward path="/account"></Forward>}></Route>
            <Route
              path="/faq"
              render={(props) =>
                Auth.isAuthenticated === true ? (
                  <LogedIn></LogedIn>
                ) : (
                  <Redirect to="/login" />
                )
              }></Route>
            <Route
              path="/admin-login"
              render={(props) =>
                Auth.isAdmin === true ? (
                  <AdminLogin></AdminLogin>
                ) : (
                  <Redirect to="/admin" />
                )
              }></Route>
            <Route path="/pricing" component={Pricing}></Route>
            <Route path="/forms:/" component={Forms}></Route>
            <Route path="/admin" component={Admin}></Route>
            <Route path="/subscribe:/" component={Unsubscribe}></Route>
            <Route path="/payment-approved" component={PaymentApproved}></Route>
            <Route path="/payment-declined" component={PaymentDeclined}></Route>
            <Route path="/privacy-policy" component={PrivacyPolicy}></Route>
            <Route path="/redirect" component={RedirectToApp}></Route>
            <Route path="/googleplay" component={GooglePlay}></Route>
            <Route path="/appstore" component={AppStore}></Route>
            <Route path="/bio" component={Bio}></Route>
            <Route path="/tr" component={Tr}></Route>
            <Route path="/en" component={En}></Route>
            <Route
              path="/terms-and-conditions"
              component={TermsAndConditions}></Route>
            <Route path="/" component={LandingPage}></Route>
            <Route component={NoMatch}></Route>
          </Switch>
        </Suspense>
      </div>
    );
  }
}

export default homepage;
