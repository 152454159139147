import React from "react";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import Switch from "react-switch";

class Unsubscribe extends Component {
  state = {
    subscribed: true,
    selectedOption: "",
  };
  componentDidMount() {
    //console.log(this.props.location.pathname.replace("/unsubscribe:/", ""));
  }
  async handleSubscriptionChange(e) {
    await this.setState({ subscribed: e });
  }
  onValueChange(event) {
    this.setState({
      selectedOption: event.target.value,
    });
  }
  saveSubscriptionChoice() {}
  render() {
    const { t } = this.props;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div className="center2" style={{ alignItems: "center" }}>
          {this.state.subscribed ? t("Subscribed") : t("Unsubscribed")}
          <Switch
            onChange={(e) => this.handleSubscriptionChange(e)}
            checked={this.state.subscribed}
          />
          <div className="radiosButtons">
            <div className="radioButtonsWrapper">
              <input
                type="radio"
                value="Female"
                checked={this.state.selectedOption === "Female"}
                onChange={(e) => this.onValueChange(e)}
              />{" "}
              Female
            </div>
            <div className="radioButtonsWrapper">
              <input
                type="radio"
                value="Male"
                checked={this.state.selectedOption === "Male"}
                onChange={(e) => this.onValueChange(e)}
              />{" "}
              Male
            </div>
            <div className="radioButtonsWrapper">
              <input
                type="radio"
                value="Other"
                checked={this.state.selectedOption === "Other"}
                onChange={(e) => this.onValueChange(e)}
              />{" "}
              {t("Other")}
            </div>
            {this.state.selectedOption === "Other" ? (
              <div className="input marginBottom">
                <input
                  type="text"
                  onChange={(e) => this.changeCompanyCategory(e)}
                  placeholder={t("UnsubscriptionReason")}
                />
              </div>
            ) : null}
          </div>

          <button
            className="loginButtons"
            onClick={() => this.saveSubscriptionChoice()}
          >
            {t("Save")}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Unsubscribe);
