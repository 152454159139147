import React from "react";
import FooterComponent from "../../footer/footerComponent";
import Toolbar from "../../toolbar/Toolbar";
import "./noMatch.css"
import { useTranslation } from "react-i18next";

const NoMatch = () => {
    const { t } = useTranslation();
  return (
    <div className="errorPage">
      <Toolbar></Toolbar>
      <h2>404 {t("pageNotFound")}</h2>
      <FooterComponent></FooterComponent>
    </div>
  );
};

export default NoMatch;
