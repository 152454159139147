import React from 'react';
import { Component } from 'react';
import Spinner from '../containers/spinner/spinner';

class Bio extends Component {
  constructor() {
    super();
    this.redirect();
  }

  componentDidMount() {
    window.location.replace('https://visitmy.bio/teamfluencer');
  }
  redirect() {
    window.amplitude.logEvent('user_redirected_to_Bio');
  }
  render() {
    return <Spinner></Spinner>;
  }
}

export default Bio;
