import React from "react";
import { Component } from "react";
import Spinner from "../containers/spinner/spinner";

class AppStore extends Component {
  constructor() {
    super();
    this.redirect();
  }
  
  componentDidMount() {
    window.location.replace(
      "https://apps.apple.com/us/app/teamfluencer/id1537074458"
    );
  }
  redirect() {
    window.amplitude.logEvent("user_redirected_to_AppStore");
  }
  render() {
    return <Spinner></Spinner>;
  }
}

export default AppStore;
