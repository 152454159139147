import React from "react";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons";
import { FiInstagram } from "react-icons/fi";
import { FaLinkedin } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const FooterComponent = (language) => {
  const { t } = useTranslation();
  if (language.language === "tr")
    return (
      <div className="footer">
        <div className="siteInfo">
          <div className="footerLogoContainer"></div>
          <div className="footerText noHover">
            All Rights Reserved © teamfluencer.co
          </div>
          <div className="footerColTitle addressMargin">{t("Contact")}</div>
          <div className="footerText">Teamfluencer Teknoloji A.Ş.</div>
          <div className="footerText">
            Esentepe Talatpaşa Caddesi No:5 Harman Sok. Girişi, Kolektif House
            Levent, 34394 Şişli/İstanbul
          </div>
          <div className="footerText">Telefon: +905352717167 </div>
          <div className="footerText">
            <a className="mailtoLink" href="mailto:hello@teamfluencer.co">
              hello@teamfluencer.co
            </a>
          </div>
          <div className="socials">
            <IconContext.Provider value={{ color: "#333333", size: 20 }}>
              <a
                href="https://www.instagram.com/teamfluencerapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiInstagram />
              </a>
            </IconContext.Provider>
            <IconContext.Provider value={{ color: "#333333", size: 20 }}>
              <a
                href="https://tr.linkedin.com/company/teamfluencerco"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
            </IconContext.Provider>
          </div>
        </div>
        <div className="footerCol">
          <div className="footerColTitle">{t("Products")}</div>
          <div className="footerColInfo">
            <a
              className="mailtoLink"
              href="https://play.google.com/store/apps/details?id=com.teamfluencer.teamfluencer"
            >
              Google Play
            </a>
          </div>
          <div className="footerColInfo">
            {" "}
            <a
              className="mailtoLink"
              href="https://apps.apple.com/tr/app/teamfluencer/id1537074458?l=tr"
            >
              Appstore
            </a>
          </div>
          {/**<div className="footerColInfo">
          <a className="mailtoLink" href="signup">
            Marka İş Birlikleri
          </a>
        </div> */}
        </div>
        <div className="footerCol">
          <div className="footerColTitle">{t("AboutUs")} </div>
          <div className="footerColInfo">
            <a className="mailtoLink" href="mailto:hello@teamfluencer.co">
              {t("Contact")}
            </a>
          </div>
          {/**<div className="footerColInfo">
          <a className="mailtoLink" href="">
            Sıkça Sorulan Sorular
          </a>
        </div> */}
        </div>
        <div className="footerCol">
          <div className="footerColTitle">{t("Privacy")}</div>
          <div className="footerColInfo">
            <Link className="mailtoLink" to="/privacy-policy">
              {t("PrivacyPolicy")}
            </Link>
          </div>
          <div className="footerColInfo">
            <Link className="mailtoLink" to="/terms-and-conditions">
              {t("TermsOfUse")}
            </Link>
          </div>
          {/* <div className="footerColInfo">
          <Link className="mailtoLink" to="/terms-and-conditions">
            Cookies Policy
          </Link>
        </div> */}
        </div>
      </div>
    );
  else
    return (
      <div className="footer">
        <div className="siteInfo">
          <div className="footerLogoContainer"></div>
          <div className="footerText noHover">
            All Rights Reserved © teamfluencer.co
          </div>
          <div className="footerColTitle addressMargin">{t("Contact")}</div>
          <div className="footerText">Teamfluencer Inc.</div>
          <div className="footerText">
            112 Capitol Trail Suite A, Newark, DE, 19711
          </div>
          <div className="footerText">
            <a className="mailtoLink" href="mailto:hello@teamfluencer.co">
              hello@teamfluencer.co
            </a>
          </div>

          <div className="socials">
            <IconContext.Provider value={{ color: "#333333", size: 20 }}>
              <a
                href="https://www.instagram.com/teamfluencerapp"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FiInstagram />
              </a>
            </IconContext.Provider>
            <IconContext.Provider value={{ color: "#333333", size: 20 }}>
              <a
                href="https://tr.linkedin.com/company/teamfluencerco"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </a>
            </IconContext.Provider>
          </div>
        </div>
        <div className="footerCol">
          <div className="footerColTitle">{t("Products")}</div>
          <div className="footerColInfo">
            <a
              className="mailtoLink"
              href="https://play.google.com/store/apps/details?id=com.teamfluencer.teamfluencer"
            >
              Google Play
            </a>
          </div>
          <div className="footerColInfo">
            {" "}
            <a
              className="mailtoLink"
              href="https://apps.apple.com/tr/app/teamfluencer/id1537074458?l=tr"
            >
              Appstore
            </a>
          </div>
          {/**<div className="footerColInfo">
        <a className="mailtoLink" href="signup">
          Marka İş Birlikleri
        </a>
      </div> */}
        </div>
        <div className="footerCol">
          <div className="footerColTitle">{t("AboutUs")} </div>
          <div className="footerColInfo">
            <a className="mailtoLink" href="mailto:hello@teamfluencer.co">
              {t("Contact")}
            </a>
          </div>
          {/**<div className="footerColInfo">
        <a className="mailtoLink" href="">
          Sıkça Sorulan Sorular
        </a>
      </div> */}
        </div>
        <div className="footerCol">
          <div className="footerColTitle">{t("Privacy")}</div>
          <div className="footerColInfo">
            <Link className="mailtoLink" to="/privacy-policy">
              {t("PrivacyPolicy")}
            </Link>
          </div>
          <div className="footerColInfo">
            <Link className="mailtoLink" to="/terms-and-conditions">
              {t("TermsOfUse")}
            </Link>
          </div>
          {/* <div className="footerColInfo">
        <Link className="mailtoLink" to="/terms-and-conditions">
          Cookies Policy
        </Link>
      </div> */}
        </div>
      </div>
    );
};

export default FooterComponent;
