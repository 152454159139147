import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IconContext } from "react-icons";
import "./payment.css";
import {withRouter} from '../../../helpers/Router';
import { useTranslation } from "react-i18next";
import Toolbar from "../../toolbar/Toolbar";
import FooterComponent from "../../footer/footerComponent";

const PaymentDeclined = (props) => {
  const { t } = useTranslation();
  const gotoLogedIn = () => {
    props.history.push("/logedIn");
  };
  //console.log(props)
  return (
    <React.Fragment>
      <Toolbar></Toolbar>
      <div className="paymentPage">
        <IconContext.Provider
          className="icon"
          value={{ color: "#ff006a", size: 60 }}
        >
          <AiOutlineClose />
        </IconContext.Provider>
        <div className="paymentText">
          {t("errorAccuredDuringPayment")} {props.code}
        </div>
        <button className="retryButton" onClick={() => gotoLogedIn()}>
          {t("TryAgain")}
        </button>
      </div>
      <FooterComponent></FooterComponent>
    </React.Fragment>
  );
};

export default withRouter(PaymentDeclined);
