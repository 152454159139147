import React, { useState } from 'react';
import Auth from '../../helpers/Auth';
import { withRouter } from './../../helpers/Router';
import 'draft-js/dist/Draft.css';
import './admin.css';

const Admin = (props) => {
  const [username, setusername] = useState('');
  const [password, setpassword] = useState('');
  const logInAdmin = () => {
    fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/auth/login`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        username,
        password,
      }),
      headers: new Headers({ 'content-type': 'application/json' }),
    })
      .then((response) => {
        if (response.status === 200) {
          Auth.authenticateAdmin();
          props.history.push({
            pathname: '/admin-login',
          });
        }
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  return (
    <div className="admin-login-container">
      <div className="admin-login-form">
        <h2 className="admin-login-title">Admin Login</h2>
        <input
          type="text"
          className="admin-input-field"
          placeholder="Username"
          onChange={(e) => setusername(e.target.value)}
        />
        <input
          type="password"
          className="admin-input-field"
          placeholder="Password"
          onChange={(e) => setpassword(e.target.value)}
        />
        <button className="admin-submit-button" onClick={() => logInAdmin()}>
          Admin Login
        </button>
      </div>
    </div>
  );
};

export default withRouter(Admin);
