import React from 'react';
import { Component } from 'react';
import Toolbar from '../toolbar/Toolbar';

class Forms extends Component {
  state = {
    url: '',
  };
  componentDidMount() {
    //console.log(this.props.location.pathname.replace("/forms:/", ""));
    window.amplitude.logEvent('forms_open');
    const url = this.props.location.pathname.replace('/forms:/', '');
    this.setState({ url: url });
  }
  render() {
    return (
      <div
        style={{
          height: '100vh',
          backgroundColor: '#ede7f6',
          overflow: 'hidden',
        }}>
        <Toolbar></Toolbar>
        <div style={{ marginTop: '4%', height: '100%' }}>
          <iframe
            src={this.state.url}
            width="100%"
            height="100%"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title='Iframe'>
            Loading…
          </iframe>
        </div>
      </div>
    );
  }
}

export default Forms;
