import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

class LandingPage extends Component {
  componentDidMount() {
    //redirect to address "teamfluencer.co"
    window.location.href = 'https://teamfluencer.co';
    window.scrollTo(0, 0);
  }
  render() {
    return <div></div>;
  }
}

export default withTranslation()(withRouter(LandingPage));
